@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

@font-face {
	font-family: 'CaslonGraphiqueEF';
	src: url('../font/CaslonGraphiqueEF.woff2') format('woff2'),
	url('../font/CaslonGraphiqueEF.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

html {
	background-color: $color-tan;
	background-image: url('../img/bg/bg-top.png'), url('../img/bg/bg-bottom.png');
	background-position: center top, center bottom;
	background-repeat: no-repeat, no-repeat;
	background-size: 100% auto, 100% auto;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 300;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-green;
}

a {
	color: $color-orange;

	@include hover-focus {
		color: $color-green;
	}
}

header {
	padding: rem(30) 0 rem(40) 0;

	.socials {
		list-style: none;
		margin: 0 0 rem(30) 0;
		padding: 0;

		> li {
			display: inline-block;
			font-size: rem(20);
			vertical-align: middle;

			+ li {
				margin-left: rem(15);
			}
		}
	}

	h1 {
		margin: 0 auto;
		max-width: rem(900);
	}
}

main {
	#contest {
		.container {
			max-width: rem(700);
		}

		h2 {
			color: $color-orange;
			font-family: 'CaslonGraphiqueEF';
			font-size: rem(32);
			font-weight: 500;
			line-height: line-height(40, 35);
			margin: 0 auto rem(30) auto;
		}

		form {

			.thanks {
				font-family: 'CaslonGraphiqueEF';
				font-size: rem(25);
			}

			input, textarea {
				border: 1px solid $color-white;
				color: $color-green;
				display: block;
				font-size: rem(16);
				letter-spacing: letter-spacing(200);
				line-height: 1;
				padding: rem(15);
				text-align: center;
				text-indent: text-indent(200);
				width: 100%;

				&:focus {
					border-color: $color-orange;
					outline: 0;
				}

				@include placeholder {
					color: $color-green;
					text-transform: uppercase;
				}

				+ input, + textarea {
					margin-top: rem(15);
				}
			}

			textarea {
				line-height: 1.5;
			}

			button {
				background: $color-orange;
				border: 0;
				color: $color-white;
				display: inline-block;
				font-family: 'CaslonGraphiqueEF';
				font-size: rem(20);
				font-weight: 500;
				letter-spacing: letter-spacing(200);
				line-height: 1;
				margin-top: rem(30);
				min-width: rem(230);
				padding: rem(15);
				text-align: center;
				text-indent: text-indent(200);
				text-transform: uppercase;
				vertical-align: middle;

				@include hover-focus {
					background: $color-green;
					color: $color-white;
				}
			}

			a {
				display: inline-block;
				margin-top: rem(15);
				vertical-align: middle;
			}
		}
	}

	#video {
		margin-top: rem(60);
	}
}

footer {
	padding: rem(100) 0 rem(60) 0;

	.copyright {
		> a {
			display: inline-block;
			margin-bottom: rem(20);
			opacity: 1;
			vertical-align: middle;

			@include hover-focus {
				opacity: 0.8;
			}
		}

		> p {
			letter-spacing: letter-spacing(100);
			line-height: line-height(20, 12);
			text-indent: text-indent(100);

			> a {
				color: $color-green;

				@include hover-focus {
					color: $color-green;
					text-decoration: underline;
				}
			}
		}
	}
}
